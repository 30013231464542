<!-- Chat.vue -->
<template>
  <div class="chat-container">
    <div class="chat-body">
      <ul>
        <li v-for="message in messages" :key="message.id" :class="{ 'no-user': !message.user }">
          <strong>{{ message.user }}:</strong> {{ message.text }}
        </li>
      </ul>
    </div>
  </div>
  <div class="chat-footer">
    <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Saisissez votre message..." />
    <button @click="sendMessage">Envoyer</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMessage: "",
    };
  },
  computed: {
    messages() {
      return this.$store.state.messages;
    },
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        console.log("send Msg !", this.newMessage);
        const roomCode = this.$store.state.roomCode;
        if (this.$store.state.socket) {
          console.log("send Msg !", { user:this.$store.state.username, text: this.newMessage });
          this.$store.state.socket.emit("newMessage", { user:this.$store.state.username, text: this.newMessage });
          this.$store.commit("addMessage", { user: "Moi", text: this.newMessage, roomCode });
          this.newMessage = "";
        } else {
          console.error("Socket not initialized or connection not established.");
        }
      }
    },
  },
  mounted() {
    this.$store.state.socket.on('newMessage', (message) => {
      console.log("New Msg !", message);
      this.$store.commit("addMessage", message);
    });
  },
};
</script>

<style scoped>
.ul {
  padding: 0px;
}
/* Styles pour le composant Chat */
.chat-container {
  border: 1px solid #ccc;
  padding: 0px;
  height: 100px;
  overflow-y: auto;
  border-radius: 6px;
}

.chat-body {
  text-align: left;
  margin-bottom: 10px;
}

.chat-body ul {
  list-style: none;
  padding: 0;
}

.chat-body li.no-user {
  opacity: 0.7;
}

.chat-footer {
  display: flex;
}

.chat-footer input {
  flex: 1;
  margin-bottom: 10px;
}

.chat-footer button {
  cursor: pointer;
}
</style>

