<template>
  <button @click="openModalIcone()">Sélectionner une icône</button>
  <div v-if="showModalIcone" class="modal">
    <!-- Contenu du modal -->
    <div class="modal-content">
      <h2>Sélectionnez une icône</h2>
      <div class="icon-list">
        <!-- Affichez votre liste d'images ici -->
        <div v-for="icon in iconList" :key="icon.id" @click="selectIcon(icon.id)">
          <img :src="getImage(icon.url)" alt="Icon" />
        </div>
      </div>
      <button @click="closeModalIcone">Fermer</button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['icon-selected'],
  data() {
    return {
      showModalIcone: false,
      iconId: 0,
      iconList: [
        { id:1, url:'logo.png'},
        { id:2, url:'logo.png'},
        { id:3, url:'logo.png'},
      ],
    };
  },
  methods: {
    getImage(name) {
      return require(`../assets/${name}`)
    },
    openModalIcone() {
      this.showModalIcone = true;
    },
    closeModalIcone() {
      this.showModalIcone = false;
    },
    selectIcon(iconId) {
      this.iconId = iconId;
      console.log('Select '+ iconId);
      this.$emit('icon-selected', iconId);
      this.closeModalIcone();
    },
  },
};
</script>


<style scoped>
/* Ajoutez le style de votre modal ici */
.modal {
  /* Styles pour le fond modal (assombrissement) */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.modal-content {
  /* Styles pour la boîte modale elle-même */
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon-list {
  /* Styles pour la liste d'icônes */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.icon-list div {
  /* Styles pour chaque icône */
  width: 50px; /* Ajustez la taille de l'icône selon vos besoins */
  height: 50px; /* Ajustez la taille de l'icône selon vos besoins */
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
}

.icon-list img {
  /* Styles pour les images d'icônes à l'intérieur du cercle */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>

