<template>
  <div>
    <!-- Partie "Rejoindre un lobby" -->
    <div>
      <form @submit.prevent="joinLobby">
        <label for="lobbyInput">Rejoindre un lobby</label>
        <input v-model="lobbyName" :type="showText ? 'text' : 'password'" id="lobbyInput" autocomplete="off" />
        <button @click="toggleShowText">^</button>
        <button type="button" @click="joinLobby">Rejoindre</button>
      </form>
    </div>

    <!-- Partie "Créer un lobby" -->
    <div>
      <button @click="createLobby">Créer un lobby</button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      lobbyName: "",
      lobbyInputs: "",
      showText: true,
    };
  },
  methods: {
    joinLobby() {
      this.$store.dispatch('joinLobby', this.lobbyName)
        .then(() => {
          this.$router.push('/lobby');
        })
        .catch(error => {
          console.log(error);
        })
    },
    createLobby() {
      this.$store.dispatch('createLobby')
        .then(result => {
          console.log(result);
          this.$router.push('/lobby');
        })
        .catch(error => {
          console.log(error);
        })
    },

    toggleShowText() {
      this.showText = !this.showText;
    },
  },
};
</script>

