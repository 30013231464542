<template>
  <div id="lobby">
    <div class="player-list">
      <h2>Liste des Joueurs</h2>
      <ul>
        <li v-for="(player, index) in extendedPlayerList" :key="index">
          <span v-if="player">
            <img width="20" height="20" src="@/assets/image/emptyicon.png" />
            <span v-if="player.id == roomData.host">[host]</span>
            <span v-if="myid == roomData.host && player.id != myid" @click="kickPlayer(player.id)" >[Kick]</span>
          </span>
          {{ player ? player.username : '...' }}
        </li>
      </ul>
    </div>

    <div class="options" v-if="roomData && myid == roomData.host">
      <h2>Options</h2>

      <div class="select-container" v-if="selectedGame">
        <label for="gameSelect">Sélectionner un Jeu :</label>
        <select id="selectedGame" v-model="selectedGame" @change="changeMaps">
          <option v-for="(game, index) in games" :key="index" v-bind:value="index" >{{ game.name }}</option>
        </select>
      </div>
      <div class="select-container">
        <label for="modeSelect">Mode :</label>
        <select id="modeSelect" v-model="selectedOption" v-if="games[selectedGame] && games[selectedGame].option" @change="changeMaps">
          <option v-for="(option, index) in games[selectedGame].option" :key="index" v-bind:value="index">{{ option.type }}</option>
        </select>
      </div>
    </div>
    <div class="options" v-if="roomData && myid != roomData.host">
      <h2>-Options-</h2>
      <div class="select-container" v-if="games && games[selectedGame]">
        <span>Jeu : {{ games[selectedGame].name }}</span>
        <br/>
        <span>Mode : {{ games[selectedGame].option[selectedOption].type }}</span>
      </div>
    </div>
    <windowChat />
  </div>
  <div>
    <button @click="toggleVisibility">{{ roomIdVisible ? 'Masquer' : 'Afficher le code' }}</button>
    <p v-if="roomIdVisible">Room ID: {{ roomData ? roomData.code : '...' }}</p>
    <button @click="copyToClipboard">Copier</button>
    <div class="start-button" v-if="roomData && myid == roomData.host" >
      <button @click="startGame">Démarrer la partie</button>
    </div>
  </div>
  <div>
  </div>  
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import windowChat from '@/components/windowChat.vue';
import { ref, watchEffect } from 'vue';
import axios from 'axios';

export default {
  components: {
    windowChat,
  },
  data() {
    return {
      players: [],
      extendedPlayerList: ref([]),
      roomData: null,
      myid: null,
      games: [],
      selectedGame: 0,
      selectedOption: 0,
      roomIdVisible: false,
      socket: null,
      refreshuser: ref(false),
    };
  },
  methods: {
    changeMaps() {
      console.log({ selected: this.selectedGame, option: this.selectedOption});
      this.$store.state.socket.emit('changeMap', ({ selected: this.selectedGame, option: this.selectedOption}));
    },
    startGame() {
      this.$store.state.socket.emit('startGames', { game:this.selectedGame, mode:this.selectedMods});
      this.$store.state.startgame = true;
      console.log("Partie démarrée avec les options suivantes:", {
        selectedGame: this.selectedGame,
        selectedMods: this.selectedMods,
      });
      this.$router.push('/purgemaster');
    },
    toggleVisibility() {
      this.roomIdVisible = !this.roomIdVisible;
      console.log(this.roomIdVisible);
    },
    copyToClipboard() {
      const textarea = document.createElement('textarea');
      textarea.value = this.roomData.code;
      document.body.appendChild(textarea);
      
      textarea.select();
      document.execCommand('copy');
      
      document.body.removeChild(textarea);
    },
    kickPlayer(id) {
      console.log("Go kick " + id);
    },
    updateRoom(newRoom) {
      this.roomData = newRoom;
      this.players = this.getUsers;
      this.refreshuser = true;
    },
    async getGames() {
      const response = await axios.get('http://46.101.199.164:3030/api/getgames');
      console.log(response);
      this.games = response.data.games;
    },
  },
  mounted() {
    this.roomData = this.getRoom;
    this.players = this.getUsers;
    this.myid = this.getMyid;
    this.getGames();

    this.refreshuser = true;
    this.$store.state.socket.on('changeMap', ((option) => {
      console.log(option);
      this.selectedGame = option.selected;
      this.selectedOption = option.option;
    }));
    this.$store.state.socket.on('startGame', (() => {
      this.$router.push('/purge');
    }));
    watchEffect(() => {
      this.refreshuser = false;
      const extendedList = Array.from({ length: 10 }, (_, index) =>
        this.players[index] || null
      );
      this.extendedPlayerList = extendedList;
    });
  },
  computed: {
    ...mapState(['roomId']),
    ...mapGetters(['getRoom']),
    ...mapGetters(['getUsers']),
    ...mapGetters(['getMyid']),
  },
  watch: {
    '$store.state.room'(newRoom) {
      console.log("Watcher : room changed");
      this.updateRoom(newRoom);
    },
  },
  beforeUnmount() {
    if (!this.$store.state.startgame && this.$store.state.socket) {
      this.$store.state.socket.emit('userdisconnect');
      this.$store.commit('clearSocket');
      this.$store.commit('clearMessage');
    }
  },
};
</script>

<style scoped>
#lobby {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.player-list,
.options {
  width: 30%;
}

.options {
  margin-right: 20px; /* Ajout de la marge entre la partie Options et le bouton Démarrer */
}

.select-container {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>

