<template>
	<HomeCarousel />
	<HomeConnect class="centered-component" />
	<HomeInscription />
</template>

<script>
import HomeCarousel from '../components/HomeCarousel.vue';
import HomeConnect from '../components/HomeConnect.vue';
import HomeInscription from '../components/HomeInscription.vue';


export default {
	name: 'HomePage',
	components: {
		HomeCarousel,
		HomeConnect,
		HomeInscription,
	},
	methods: {
		openInscriptionModal() {
			this.$refs.inscriptionModal.openModal();
		},
		handleInscriptionComplete() {
			console.log('Inscription réussie !');
		},
    handleIconSelected(iconId) {
      console.log('ID de l\'icône sélectionnée:', iconId);
    },
	},
}
</script>

<style>
.sizemax {
  display: grid;
  grid-template-rows: auto 1fr auto; /* En-tête, contenu extensible, pied de page */
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.centered-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  flex: 0;
  display: flex;
}
</style>
