<template>
  <div class="home-connect">
    <div class="pseudo-container">
      <span class="bold-label rounded-input" style="font-size: 32px; align-items: center;" >{{ $t('ContinueWithoutConnection') }}</span>
      <br />
      <br />
      <form @submit.prevent="submitFormNoConnect">
        <label for="pseudo" class="bold-label rounded-input larger-font">Pseudo : </label>
        <input type="text" id="pseudo" placeholder="Enter your pseudo" v-model="pseudo" class="rounded-input" />
      <button type="submit">-></button>
      </form>
    </div>
    <div class="separator" v-if="isMobile"></div>
    <div class="login-container">
      <span class="bold-label rounded-input" style="font-size: 32px; align-items: center;" >Se connecter</span>
      <br />
      <br />
      <form @submit.prevent="submitForm">
        <label for="username" class="bold-label rounded-input larger-font">Username : </label>
        <input type="text" id="username" autocomplete="username" placeholder="Enter your username" v-model="username" class="rounded-input" @keyup.enter="moveFocusToPassword" />
        <br />
        <label for="password" class="bold-label rounded-input larger-font">Password : </label>
        <input type="password" id="password" autocomplete="password" placeholder="Enter your password" v-model="password" class="rounded-input" @keyup.enter="submitForm" ref="passwordInput" />
        <button type="submit">Connexion</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      username: '',
      password: '',
      pseudo: 'MonPseudoDuTurfu',
      isMobile: false,
    };
  },
  mounted() {
    Cookies.remove('user');
    window.addEventListener('resize', this.checkWindowSize);
    this.checkWindowSize();
  },
  methods: {
    checkWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    moveFocusToPassword() {
      this.$refs.passwordInput.focus();
    },
    async submitForm() {
      try {
        const res = await axios.post('http://46.101.199.164:3030/api/connection', {
          username: this.username,
          password: this.password,
        });
        if (res.status === 200) {
          this.$store.commit('setUsername',this.username);
          Cookies.set('user', this.username, { expires:1, path: '/' });
          this.$router.push('home');
        } else {
          console.error('Erreur de co');
        }
      } catch (error) {
        console.error('Erreur de la requete Axios', error);
      }
    },
    async submitFormNoConnect() {
      this.$store.commit('setUsername',this.pseudo);
      Cookies.set('user', this.username, { expires:1, path: '/' });
      this.$router.push('home');
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
};
</script>

<style scoped>
.home-connect {
  display: flex;
  width: 100%;
}

.pseudo-container,
.login-container {
  flex: 1;
  padding: 10px;
}

.separator {
  width: 1px;
  background-color: #ccc;
  height: 100%;
  margin: 0 10px;
  display: none;
}

.rounded-input {
  font-family: 'Preahvihear', sans-serif; /* Remplacez 'Your Rounded Font' par la police souhaitée */
  border-radius: 10px;
}

.bold-label {
  font-weight: bold;
}

.larger-font {
  font-size: 16px; /* Ajustez la taille de la police selon vos préférences */
}

@media (max-width: 767px) {
  .home-connect {
    flex-direction: column;
  }

  .separator {
    display: block;
    margin: 10px 0;
  }
}
</style>

