<!-- ChatWindow.vue -->
<template>
  <div class="chat-window">
    <button @click="toggleChat">Chat</button>
    <messageChat v-if="showChat" />
  </div>
</template>

<script>
import messageChat from "@/components/messageChat.vue";

export default {
  data() {
    return {
      showChat: true,
    };
  },
  methods: {
    toggleChat() {
      if (this.$store.state.socket) {
        this.showChat = !this.showChat;
      }
    },
  },
  components: {
    messageChat,
  },
};
</script>

<style scoped>
/* Styles pour la fenêtre de chat */
.chat-window {
  position: fixed;
  bottom: 10px;
  left: 0px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 10px;
}
</style>

