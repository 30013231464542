// Import des dépendances nécessaires
import { createStore } from 'vuex';
import io from 'socket.io-client';
import selectThemeModule from '@/store/modules/selectTheme';
import { Room } from './room';


const actions = {
  async initializeSocket({ commit, state }) {
    try {
      if (!state.socket) {
        const socket = io('http://www.okaawaa.com:3030');

        socket.on('error', (error) => {
          console.error('Socket.IO Error:', error);
        });

        socket.on('disconnect', () => {
          socket.emit('userdisconnect');
          commit('clearSocket');
        });

        commit('setSocket', socket);
      }
    } catch (error) {
      console.error('Erreur lors de l\'initialisation du socket:', error);
    }
  },

  async createLobby({ commit, dispatch, state }) {
    try {
      await dispatch('initializeSocket');
      state.socket.on('userUpdated', (user) => {
        if (user) {
          state.users = user;
        }
      });
      state.socket.on('roomUpdated', (room) => {
        if (room) {
          state.room = room.room;
          state.roomCode = room.roomCode;
          state.users = room.room.users;
        }
      });

      const response = await new Promise((resolve, reject) => {
        state.socket.on('roomInfo', (data) => {
          if (data.room) {
            state.room = data.room;
            state.myid = data.id;
            resolve(data);
          } else {
            reject(new Error('Échec de la création du lobby.'));
          }
        });
        state.socket.emit('createLobby', state.username);
      });

      state.roomCode = response.room.roomCode;
      commit('setRoom', response.room);
    } catch (error) {
      console.error('Erreur lors de la connexion au lobby:', error.message);
    }
  },
  async kickPlayer(kickid) {
    state.socket.on('userUpdated', (user) => {
      if (user) {
        state.users = user;
      }
    });
    state.socket.emit('kickPlayer', kickid);
  },
  async joinLobby({ commit, dispatch, state }, roomCode) {
    try {
      await dispatch('initializeSocket');
      state.socket.on('userUpdated', (user) => {
        if (user) {
          state.users = user;
        }
      });
      state.socket.on('roomUpdated', (room) => {
        if (room) {
          state.room = room.room;
          state.roomCode = room.roomCode;
          state.users = room.room.users;
        }
      });
      const response = await new Promise((resolve, reject) => {
        state.socket.on('roomInfo', (data) => {
          if (data.room) {
            state.room = data.room;
            state.roomCode = roomCode;
            state.myid = data.id;
            resolve(data);
          } else {
            state.roomCode = null;
            reject(new Error('Échec de la création du lobby.'));
          }
        });
        state.socket.emit('joinLobby', state.username, roomCode);
      });
      state.roomCode = roomCode;
      commit('setRoom', response.room);
    } catch (error) {
      console.error('Erreur lors de la connexion au lobby:', error.message);
    }
  },

};

const mutations = {
  updateLanguage(state, newlanguage) {
    state.language = newlanguage;
  },
  setSocket(state, socket) {
    console.log("setSocket =", socket);
    state.socket = socket;
  },
  setUsername(state, username) {
    console.log("username =", username);
    state.username = username;
  },
  setRoom(state, room) {
    console.log("setRoom =", room);
    state.room = new Room(room.code, room.mode);
    state.room.host = room.host;
    state.users = room.users;
    console.log("state Room "+ room + " User " + room.users);
  },
  clearSocket(state) {
    if (state.socket) {
      state.socket.disconnect();
    }
    state.socket = null;
  },
  clearRoom(state) {
    state.room = null;
    state.roomCode = null;
    state.users = null;
    state.messages = [];
  },
  clearMessage(state) {
    state.messages = [];
  },
  addMessage(state, message) {
    state.messages.push(message);
  },
};

const getters = {
  getSocket: (state) => state.socket,
  getRoom: (state) => state.room,
  getRoomcode: (state) => state.roomCode,
  getUsers: (state) => state.users,
  getMyid: (state) => state.myid,
};

const state = {
  language: 'fr',
  username: '',
  socket: null,
  room: null,
  roomCode: null,
  users: null,
  myid:null,
  startgame: false,
  messages: [],
};

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    selectTheme: selectThemeModule,
  },
});

