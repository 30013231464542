import { createApp } from 'vue';
import i18n from './vue-i18n.js'
import { createMetaManager } from 'vue-meta';

import store from './store/store';
import router from './router';

import App from './App.vue';

const app = createApp(App);

app.use(createMetaManager());
app.use(store);
app.use(i18n);

app.use(router);

app.mount('#app');

