<template>
  <div id="app">
    <allHeader />
    <div style="margin-top: 100px">
      <router-view>
      </router-view>
    </div>
  </div>
</template>

<script>
import allHeader from './components/Header.vue';

export default {
	name: 'App',
  components: {
    allHeader,
  },
  metaInfo() {
    return {
      title: 'LoLGames',
      meta: [
        { property: 'og:title', content: 'LoLgames' },
        { property: 'og:description', content: 'Site de MiniJeux sur le Theme de LoL' },
        { property: 'og:image', content: '' },
      ],
    };
  },
}
</script>

<style>
#app::before,
#app::after,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh; /* Utilise la hauteur totale de la fenêtre */
  margin: 0;
  position: relative;
  overflow: hidden;
}

#app::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(~@/assets/image/background.png) repeat;
  mix-blend-mode: screen; /* Mode de fusion "screen" */
  opacity: 0.5; /* Ajustez l'opacité ici (10%) */
  z-index: -1; /* Placez cette couche derrière le contenu principal */
}
</style>
