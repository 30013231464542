// router.js

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './view/HomePage.vue';
import Hokko from './view/HokkoPage.vue';
import Mafu from './view/MafuPage.vue';
import purgePlayer from './view/PurgePlayer.vue';
import purgeMaster from './view/PurgeMaster.vue';
import MainMenu from './view/MainMenu.vue';
import LobbyPage from './view/LobbyPage.vue';
import LoLQuizz from './view/lolQuizz.vue';
import LoLQuizzResult from './view/lolQuizzResult.vue';
import Cookies from 'js-cookie';

const routes = [
  { path: '/', name: 'home', component: HomePage },
  { path: '/hokko', name: 'hokko', component: Hokko },
  { path: '/mafugaming', name: 'mafuyu', component: Mafu },
  { path: '/purgemaster', name: 'purgeMaster', component: purgeMaster },
  { path: '/purge', name: 'purgePlayer', component: purgePlayer },
  { path: '/home', name: 'main', component: MainMenu },
  { path: '/lobby', name: 'lobby', component: LobbyPage },
  { path: '/quizz', name: 'quizz', component: LoLQuizz },
  { path: '/quizzresult', name: 'quizzresult', component: LoLQuizzResult },
  { path: '/:catchAll(.*)', redirect: { name: 'home' } }, // Redirection
  // ... autres routes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Vérifiez la présence du cookie de connexion ici
  const isConnected = Cookies.get('user');

  if (isConnected == undefined && to.name !== 'home' && to.name !== 'mafuyu' && to.name !== 'hokko') {
    // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;

