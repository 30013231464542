<template>
  <div>
    <div v-if="showQuestion">
      <h2>{{ currentQuestion.question }}</h2>
      <ul>
        <li v-for="(option, index) in currentQuestion.options" :key="index">
          <button @click="selectOption(index)">{{ option }}</button>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Temps écoulé !</p>
      <button @click="nextQuestion">Question suivante</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        { question: "Question 1", options: ["Option A", "Option B", "Option C", "Option D"], answer: 0 },
        // ... Ajoutez d'autres questions
      ],
      currentQuestionIndex: 0,
      currentQuestion: null,
      showQuestion: true,
    };
  },
  methods: {
    selectOption(index) {
      if (index === this.currentQuestion.answer) {
        this.$store.state.socket.emit("Game", { name:"LoLQuizz", todo:"IncrementPoints" });
      }
      this.nextQuestion();
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.currentQuestion = this.questions[this.currentQuestionIndex];
        this.showQuestion = true;

        // Démarrez un compte à rebours de 10 secondes pour chaque question
        setTimeout(() => {
          this.showQuestion = false;
        }, 10000);
      } else {
        // Toutes les questions ont été posées, peut-être rediriger ou afficher les résultats
        this.$router.push("/quizzresult");
      }
    },
  },
  created() {
    // Initialiser la première question
    this.$store.state.socket.emit("initGame",{ name:"LoLQuizz" });
    this.currentQuestion = this.questions[this.currentQuestionIndex];
    // Écoutez les événements Socket.io pour gérer la synchronisation avec les autres joueurs
    const socket = this.$store.state.socket;
    if (socket) {
      socket.on("quizNextQuestion", () => {
        this.nextQuestion();
      });
    }
  },
};
</script>

