<template>
  <div>
    <windowChat />
    <div v-if="!jeuCommence">
      <h2>Paramètres du Jeu</h2>
      <form @submit.prevent="handleInit">
        <textarea v-model="jsonInput" placeholder="Collez votre configuration JSON ici"></textarea>
        <button type="submit">Initialiser le Jeu</button>
      </form>
      <form @submit.prevent="handleSubmit">
        Nombre de Joueurs
      <input type="number" v-model.number="nombreJoueurs" placeholder="Nombre de joueurs" />
      <br>
        Nombre d'anges
      <input type="number" v-model.number="nombreAnges" placeholder="Nombre d'anges" />
      <br>
        Nombre de Pactisants
      <input type="number" v-model.number="nombrePactisants" placeholder="Nombre de pactisants" />
      <div v-for="n in nombreJoueurs" :key="n">
        <label :for="'joueur-' + n">Joueur {{ n }}:</label>
        <input type="text" :id="'joueur-' + n" v-model="nomsJoueurs[n - 1]" />
      </div>
      <br>
      <button type="submit">Commencer le Jeu</button>
      </form>
    </div>
    <div v-if="jeuCommence" class="tableau-de-jeu">
      Config : {{ jsonConfig }}
      <h3>Tableau de Jeu</h3>
      <div v-for="joueur in joueurs" :key="joueur.id" class="joueur">
        <div class="joueur-info">
          <div class="joueur-nom">Nom: <input type="text" v-model="joueur.nom" placeholder=""></div>
          <div class="joueur-role">
            <b>Rôle: {{ joueur.rolePrincipal }}</b>
            <br>
            <!-- span class="role-description"><i>
            {{ showDesc[joueur.id] ? joueur.roleDescription : joueur.roleDescription.substring(0, 20) + '...' }}</i></span -->
          </div>
          <div class="joueur-sous-role">
            <b>Sous-Rôle: {{ joueur.sousRole }}</b>
            <br>
            <span class="sous-role-description"><i>
            {{ showDesc[joueur.id] ? joueur.sousRoleDescription.substring(0, 20) + '...' : joueur.sousRoleDescription }}</i></span>
            <button @click="toggleDescription(joueur.id)">
              {{ showDesc[joueur.id] ? '+' : '-' }}
            </button>
          </div>
          <div class="joueur-statut">
            Statut:
            <select v-model="joueur.statut">
              <option value="enVie">En vie</option>
              <option value="mort">Mort</option>
              <option value="contamine">Contaminé</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import windowChat from '@/components/windowChat.vue';
import axios from 'axios';

export default {
  name: 'pageMafu',
  components: {
    windowChat,
  },
  data() {
    return {
      nombreJoueurs: 0,
      nomsJoueurs: [],
      nombreAnges: 0,
      nombrePactisants: 0,
      jeuCommence: false,
      showDesc: {},
      joueurs: [],
      jsonInput: "",
      jsonConfig: ""
    };
  },
  methods: {
    handleInit() {
      try {
        const config = JSON.parse(this.jsonInput);
        this.nombreJoueurs = config.nombreJoueurs;
        this.nomsJoueurs = config.nomsJoueurs.map(obj => obj.nom);
        this.nombreAnges = config.nombreAnges;
        this.nombrePactisants = config.nombrePactisants;
      } catch (error) {
        console.error('Erreur lors de l’analyse du JSON', error);
      }
    },
    toggleDescription(joueurId) {
      this.showDesc[joueurId] = !this.showDesc[joueurId];
    },
    async handleSubmit() {
      const parametres = {
        nombreJoueurs: this.nombreJoueurs,
        nombreAnges: this.nombreAnges,
        nombrePactisants: this.nombrePactisants,
        nomsJoueurs: this.nomsJoueurs.map((nom, index) => ({ nom: nom || `Joueur ${index + 1}` }))
      };
      this.jsonConfig = parametres;
      try {
        const response = await axios.post('http://46.101.199.164:3030/api/parametres', parametres);
        this.joueurs = response.data;
        this.jeuCommence = true;
      } catch (error) {
        console.error('Erreur lors de l’envoi des paramètres', error);
      }
    }
  }
};
</script>

<style>

/*html, body {
  overflow: auto;
}*/

.tableau-de-jeu {
  font-size: 14px;
  /* Styles pour le tableau de jeu */
}

.joueur {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligner les éléments au début de l'axe transversal */
  gap: 10px; /* Espace entre les éléments */
  margin-bottom: 20px; /* Espace entre chaque joueur */
}

.joueur-info {
  display: flex;
  align-items: center; /* Aligner les éléments verticalement */
  gap: 10px; /* Espace entre les éléments */
}

.joueur-nom, .joueur-role, .joueur-sous-role, .joueur-statut {
  /* Ajouter des styles spécifiques si nécessaire */
}

/* Votre CSS ici */
</style>

