<template>
  <h1>HOKKO PAGE !</h1>
  <br/>
	<div class="main">
    <img class="imgmax" src="https://tenor.com/view/darling-in-the-franxx-zero-two-002-foot-laundry-gif-11735634.gif">
  </div>
</template>

<script>

export default {
	name: 'pageHokko',
}

</script>


<style>

.imgmax {
  display:block;
  width:100%; 
  height:100%;
  object-fit: cover;
}

.main {
  flex: 1;
  display: flex;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
