// user.js
class User {
  constructor(id, username) {
    this.id = id;
    this.username = username;
    this.avatar = null;
    this.gameData = {};
  }

  setGameData(gameName, data) {
    this.gameData[gameName] = data;
  }

  getGameData(gameName) {
    return this.gameData[gameName];
  }
}

module.exports = User;

