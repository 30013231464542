<template>
  <div>
    <h1>Liste des joueurs</h1>
    <div v-for="player in players" :key="player.id">
      <div v-if="myid != player.id">
        <p>{{ player.username }}</p>
      </div>
      <div v-if="myid == player.id">
        <h2>{{ player.rolePrincipale }}</h2>
        <img src="" />
        <p>{{ player.username }}</p>
        <p>{{ player.sousRoleDescription }}</p>
        <p>Statut : {{ player.statut }}</p>
      </div>
      <windowChat />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import windowChat from '@/components/windowChat.vue';

export default {
  components: {
    windowChat,
  },
  name: 'pageMafuPlayer',
  data() {
    return {
      players: [],
      myid: null,
      socket: null,
    };
  },
  methods: {
  },
  mounted() {
    this.players = this.getUsers;
    this.myid = this.getMyid;
  },
  computed: {
    ...mapGetters(['getMyid']),
    ...mapGetters(['getUsers']),
  },
};
</script>

<style scoped>
</style>

