<template>
  <div>
    <h2>Résultats !</h2>
    <h1>Classement</h1>
    <ul>
      <li v-for="(user, index) in sortedUsers" :key="index">
        {{ user.username }}: {{ user.gameData["LoLQuizz"].points }} points
      </li>
    </ul>
    <router-link to="/lobby">Retour au lobby</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortedUsers: [],
    };
  },
  created() {
    const socket = this.$store.state.socket;

    if (socket) {
      socket.on('roomData', (roomData) => {
        this.sortedUsers = this.sortUsersByPoints(roomData.users);
      });
      socket.emit('getRoomData');
    }
  },
  methods: {
    sortUsersByPoints(users) {
      return users.sort((a, b) => {
        const pointsA = a.gameData["LoLQuizz"].points;
        const pointsB = b.gameData["LoLQuizz"].points;
        return pointsA - pointsB;
      });
    },
  },
};
</script>

<style scoped>
</style>

