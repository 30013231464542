<template>
	<header>
    <select v-model="selectedLanguage" @change="changeLanguage">
      <option value="fr">Français</option>
      <option value="en">English</option>
    </select>
    <h3 @click="mainPage">{{ $t('Welcome') }}</h3>
    <select id="selectedTheme" v-model="selectedTheme" @change="setTheme">
      <option value="light" class="fa-option">Light</option>
      <option value="dark" class="fa-option">Dark</option>
      <option value="sakura" class="fa-option">Sakura</option>
    </select>
	</header>
</template>

<script>
import '@/assets/styles.css';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'allHeader',
  setup() {
    const store = useStore();
    const selectedTheme = ref(store.state.selectTheme.selectedTheme);
    const selectedLanguage = ref(store.state.language);

    function changeLanguage(event) {
      const newOption = event.target.value;
      selectedLanguage.value = newOption;
      store.commit('updateLanguage', selectedLanguage.value);
    }

    function setTheme(event) {
      const theme = event.target.value;
      selectedTheme.value = theme;
      store.commit('setTheme', selectedTheme.value);
    }

    // Utilisez watch pour réagir aux changements du store
    watch(
      () => store.getters['getTheme'],
      (newTheme) => {
        selectedTheme.value = newTheme;
        document.body.className = '';
        document.body.classList.add('theme-' + selectedTheme.value);
      }
    );

    selectedTheme.value = 'dark';
    document.body.className = '';
    document.body.classList.add('theme-' + selectedTheme.value);

    return {
      setTheme,
      selectedLanguage,
      changeLanguage,
      selectedTheme,
    };
  },
  watch: {
    selectedLanguage(newLanguage) {
      this.$i18n.locale = newLanguage;
    },
  },
  methods: {
    mainPage() {
      this.$router.push('/');
    },
  },
};



</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 3%;
  z-index: 1000; /* Ajustez cela si nécessaire pour éviter d'éventuels chevauchements */
  padding: 10px 5%;
  background-color: #3333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}


</style>
