<!-- InscriptionModal.vue -->

<template>
  <div>
    <button ref="bmodalInscription" @click="showModal">S'inscrire</button>
    <div v-if="isModalVisible" class="modal-overlay">
      <div ref="modalInscription" class="modal-container">
        <div class="modal-content">
          <span @click="closeModal" class="close-button">&times;</span>
          <h2>Inscription</h2>
            <div class="form-group">
              <label for="icone">Choix d'icone:</label>
              <label for="pseudo">Prout</label>
              <br/>
              <HomeIconeSelector ref="modalIcon" @icon-selected="handleIconSelected" />
            </div>
            <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="pseudo">Username:</label>
              <input type="text" id="pseudo" v-model="pseudo" />
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" id="password" v-model="password" />
            </div>
            <button type="submit">S'inscrire</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/styles.css';
import { mapGetters } from 'vuex';
import axios from 'axios'
import HomeIconeSelector from './HomeIconeSelector.vue'

export default {
  components: {
    HomeIconeSelector,
  },
  data() {
    return {
      isModalVisible: false,
      pseudo: '',
      password: '',
      icon: 0,
    };
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  },
  mounted() {
    // Ajoutez un écouteur d'événements au niveau de l'application pour gérer les clics
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    // N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleIconSelected(iconId) {
      console.log("Icon !" + iconId);
      this.icon = iconId;
    },
    handleClickOutside(event) {
      if (this.isModalVisible && !this.$refs.modalInscription.contains(event.target) && !this.$refs.bmodalInscription.contains(event.target)) {
        this.closeModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async submitForm() {
      try {
        const response = await axios.post('http://46.101.199.164:3030/api/inscription', {
          pseudo: this.pseudo,
          password: this.password,
          icon: this.icon,
        });
        console.log('Reponse de la requete:', response.data);
        this.closeModal();
      } catch (error) {
        console.error('Erreur lors de la requete Axios', error);
      }
    },
  },
};
</script>

<style scoped>
/* Styles du fond assombri */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent noir */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  margin-right: 10px;
}

/* Le reste de vos styles */
</style>

