<template>
  <div class="swiper-container" ref="swiperRef">
    <div class="swiper-wrapper">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="swiper-slide"
      >
        <img :src="getImagePath(slide.fileName, slide.extension)" alt="Slide Image" class="carousel-image" />
        <div class="text-container">
          <h3>{{ slide.title }}</h3>
          <p>{{ slide.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SwiperCore, { Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Autoplay]);

export default {
  setup() {
    const slides = [
      { title: 'Title 1', description: 'Description 1', fileName: 'game1', extension: 'png' },
      { title: 'Title 2', description: 'Description 2', fileName: 'game2', extension: 'jpeg' },
      // Ajoutez d'autres diapositives avec les noms de fichiers complets
    ];

    const swiperRef = ref(null);

    onMounted(() => {
      new SwiperCore(swiperRef.value, {
        slidesPerView: "auto",
        spaceBetween: 10,
        autoplay: {
          delay: 5000,
        },
        loop: true,
      });
    });

    const getImagePath = (fileName, extension) => {
      return require(`@/assets/image/${fileName}.${extension}`);
    };

    return { slides, swiperRef, getImagePath };
  },
};
</script>

<style scoped>
.carousel-image {
  max-height: 300px;
  width: auto;
  object-fit: cover;
}

.text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fond avec opacité de 0.5 */
  color: white;
  text-align: center;
  padding: 10px; /* Ajoutez un padding pour plus de lisibilité */
}

/* Vos autres styles ici */
</style>

