const User = require('./userclass');

class Room {
  constructor(code, mode) {
    this.host = null;
    this.code = code;
    this.mode = mode;
    this.users = [];
  }

  addUser(user) {
    if (user instanceof User) {
      this.users.push(user);
    } else {
      throw new Error('L\'objet ajouté à la room n\'est pas une instance de la classe User.');
    }
  }

  removeUser(userId) {
    this.users = this.users.filter(user => user.id !== userId);
  }

  isEmpty() {
    return this.users.length === 0;
  }
}

class RoomManager {
  constructor() {
    this.rooms = new Map();
    this.cleanupInterval = setInterval(() => this.cleanupRooms(), 60000);
  }

  createRoom(roomCode, mode) {
    if (!this.rooms.has(roomCode)) {
      const room = new Room(roomCode, mode);
      this.rooms.set(roomCode, room);
      return room;
    } else {
      return null;
    }
  }

  getRoomByUser(userId) {
    for (const room of this.rooms.values()) {
      const userIndex = room.users.findIndex(user => user.id === userId);
      if (userIndex !== -1) {
        return room;
      }
    }
    return null;
  }

  removeUser(userId) {
    for (const room of this.rooms.values()) {
      room.removeUser(userId);
    }
  }

  getRoom(roomCode) {
    return this.rooms.get(roomCode);
  }

  getAllRooms() {
    return Array.from(this.rooms.values());
  }

  addUserToRoom(roomCode, user) {
    const room = this.getRoom(roomCode);
    if (room) {
      room.addUser(user);
      if (room.host == null) {
        room.host = user.id;
      }
      return room;
    } else {
      return null;
    }
  }

  cleanupRooms() {
    for (const [roomCode, room] of this.rooms.entries()) {
      if (room.isEmpty()) {
        this.rooms.delete(roomCode);
        console.log(`La room ${roomCode} a été supprimée car elle est vide.`);
      }
    }
  }

}


function generateRandomCode(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomCode = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCode += characters.charAt(randomIndex);
  }

  return randomCode;
}

module.exports = { Room, RoomManager, generateRandomCode };
