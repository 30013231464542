// store/modules/selectTheme.js
const state = {
  selectedTheme: 'dark',
};

const mutations = {
  setTheme(state, value) {
    state.selectedTheme = value;
    // Logique supplémentaire pour changer le thème si nécessaire
    document.body.className = '';
    document.body.classList.add('theme-' + state.selectedTheme);
  },
};

const getters = {
  getTheme: (state) => state.selectedTheme,
};

export default {
  state,
  mutations,
  getters,
};

